import { render, staticRenderFns } from "./ViewAssignedUser.vue?vue&type=template&id=b7495af8&"
import script from "./ViewAssignedUser.vue?vue&type=script&lang=js&"
export * from "./ViewAssignedUser.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBadge,QSpace,QCheckbox});
