<template>
  <pro-menu-layout>
    <div class="q-pa-md">
      <pro-deck
        :title="getRes('CustMenu.Com.Action.ViewAssignedUser')"
        :cards="userRoleList"
        hide-count-badge
        v-slot="{ card: userRole }"
      >
        <pro-deck-card :title="getRes(userRole.displayName)" expandable>
          <div v-if="userRole.userList.length === 0" class="text-center">
            {{ getRes("Document.AssignedUser.NoUser") }}
          </div>
          <div v-else class="q-gutter-y-sm">
            <div
              v-for="user in userRole.userList"
              :key="user.id"
              class="row no-wrap items-center q-col-gutter-md"
              :class="user.enabled ? '' : 'prosmart-disable'"
            >
              <div class="col-auto">
                <div class="text-h5">{{ user.displayName }}</div>
              </div>
              <div v-if="isCreator(user)" class="col-auto">
                <q-badge outline color="primary">{{
                  getRes("Document.AssignedUser.Creator")
                }}</q-badge>
              </div>
              <q-space />
              <div class="col-auto">
                <q-checkbox
                  disable
                  dense
                  :value="user.sendEmail"
                  :label="getRes('Document.AssignedUser.Notification')"
                />
              </div>
              <div class="col-auto">
                <q-checkbox
                  disable
                  dense
                  :value="!user.enabled"
                  :label="getRes('Form.Field.Disable')"
                />
              </div>
            </div>
          </div>
        </pro-deck-card>
      </pro-deck>
    </div>
  </pro-menu-layout>
</template>

<script>
import ProMenuLayout from "@/components/PROSmart/Layout/ProMenuLayout";
import ProDeck from "@/components/PROSmart/Deck/ProDeck";
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard";

export default {
  components: { ProMenuLayout, ProDeck, ProDeckCard },
  data() {
    return {
      userRoleList: [],
      moduleName: "",
    };
  },
  methods: {
    isCreator(user) {
      return user.roleName === "ProSmartBuyer" && user.sequence === 1;
    },
  },
  created() {
    this.tenderId = parseInt(this.$route.params.id);
    this.$proSmart.documentUi.getInfo(this, this.tenderId).then((formData) => {
      this.moduleName = formData.moduleName;
    });
    Promise.all([
      this.$proSmart.codeTable.getTenderUserRole(this),
      this.$proSmart.tender.getAllTenderUserRoleList(
        this,
        this.$route.params.id
      ),
      this.$proSmart.tender.getAllNoLoginUserList(this, this.$route.params.id),
    ]).then(([userRoleList, tenderUserList, noLoginUserList]) => {
      this.userRoleList = userRoleList.reduce((arr, userRole) => {
        const userList = tenderUserList.filter(
          (user) => user.roleName === userRole.keyName
        );
        if (userList.length > 0) {
          arr.push({
            ...userRole,
            key: userRole.id,
            userList: userList,
          });
        }

        const noLoginList = noLoginUserList
          .filter((noLoginUser) => noLoginUser.roleName === userRole.keyName)
          .map((noLoginUser) => ({
            ...noLoginUser,
            displayName: noLoginUser.assessorName,
          }));
        if (noLoginList.length > 0) {
          arr.push({
            ...userRole,
            displayName: `${userRole.displayName}NoLogin`,
            key: `${userRole.id}NoLogin`,
            userList: noLoginList,
          });
        }

        return arr;
      }, []);
      if (this.moduleName === "EOI") {
        this.userRoleList.forEach((item) => {
          if (item.displayName === "System.Doc.UserRole.ShortlistAssessor") {
            item.displayName = "System.Doc.UserRole.ShortlistAssessorEOI";
          }
        });
      }
    });
  },
};
</script>
